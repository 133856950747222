<template>
	<div class="login">
		<div class="qjc-agent-navbar">
			
		</div>
		
		<div class="form qjc-agent-inputs">
			<h2 class="title">
				Hello，<br> 
				<span>歡迎來到</span>
				<img src="../../../assets/images/agent_logo.png" alt="">
			</h2>
			
			<div class="nav qjc-fts-30">
				<span @click="type = 1" :class="{ active: type == 1 }">手機號登錄</span>
				<span @click="type = 2" :class="{ active: type == 2 }">賬號登錄</span>
			</div>
			
			<!-- 手机号登录 -->
			<div v-if="type == 1">
				<van-field
					v-model="mobile"
					placeholder="請輸入手機號"
					type="number"
					class="mobile"
					@input="mobileChange"
				>
					<template #label>
						<span @click="loaShow = true">+{{ columns[loaIndex].text }}</span>
					</template>
				</van-field>
				
				<van-field
					v-model="code"
					placeholder="請輸入驗證碼"
					maxLength="4"
					@input="isCanLogin"
				>
					<template #button>
						<span v-show="sendCoding" class="code-count-down qjc-fts-28">
							<van-count-down
								class="qjc-inline qjc-fts-28"
								format="ss"
								:time="2 * 60 * 1000"
								:auto-start="false"
								@finish="countDownFinish"
								ref="codeCountDown"
							/>
							秒後重新發送
						</span>
						<van-button v-show="!sendCoding" @click="sendCode" class="send-code qjc-c-primary">發送驗證碼</van-button>
					</template>
				</van-field>
				
				<van-field
					v-model="invitation"
					placeholder="請輸入邀請碼(如有)"
					:readonly="onlyRead"
				></van-field>
				
			</div>
		
			<!-- 账号密码登录 -->
			<div v-if="type == 2" class="account-login">
				<van-field
					v-model="account"
					label="賬號"
					placeholder="請輸入賬號"
					@input="isCanLogin"
				></van-field>
				
				<van-field
					v-model="password"
					label="密碼"
					type="password"
					placeholder="請輸入密碼"
					@input="isCanLogin"
				></van-field>
				
			</div>
			
		</div>
		
		<van-button @click="login" :disabled="!canLogin" class="login-btn qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
			<span class="qjc-vertical-m">登錄</span>
			<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
		</van-button>
		
		<p @click="agrementShow = true" class="qjc-c-primary qjc-fts-22 qjc-ftw-n">登錄即代表您已閱讀並同意《使用協議》</p>
		
		<!-- 图片验证码 -->
		<van-popup v-model="imgCodeShow" closeable :lazy-render="false" :close-on-click-overlay="false" class="img-code qjc-agent-inputs">
			<div class="img-code-box">
				<van-field
					v-model="imgCode"
					placeholder="請輸入圖形驗證碼"
					maxlength="4"
					autofocus
					@input="verifyImgCode"
				></van-field>
				<img @click="getImgCode" :src="imgCodeUrl" alt="圖形驗證碼">
			</div>
		</van-popup>
		
		<!-- 地区选择 -->
		<van-popup 
			v-model="loaShow" 
			position="bottom"
		>
			<van-picker
			  title=""
			  show-toolbar
			  :columns="columns"
			  @confirm="checkLoa"
			  @cancel="loaShow = false"
			/>
		</van-popup>
		
		<!-- 使用协议 -->
		<van-popup
			v-model="agrementShow"
			closeable
			class="agrement"
		>
			<h2>代理人平台用戶協議</h2>

			<div class="sub qjc-fts-24">

				<div class="list">一、特別提示</div>
				在此特別提醒您（用戶）在註冊成為米因健康大數據使用者之前，請認真閱讀本《米因健康大數據使用者協議》（以下簡稱“協議”），確保您充分理解本協議中各條款。請您審慎閱讀並選擇接受或不接受本協議。除非您接受本協議所有條款，否則您無權註冊、登錄或使用本協議所涉服務。您的註冊、登錄、使用等行為將視為對本協議的接受，並同意接受本協議各項條款的約束。
				<br /><br />
				本協議約定米因健康大數據與使用者之間關於“米因健康大數據”服務（以下簡稱“服務”）的權利義務。“用戶”是指註冊、登錄、使用本服務的個人。本協議可由米因健康大數據隨時更新，更新後的協議條款一旦公佈即代替原來的協議條款，恕不再另行通知，用戶可在本APP中查閱最新版協議條款。在修改協議條款後，如果使用者不接受修改後的條款，請立即停止使用米因健康大數據提供的服務，使用者繼續使用米因健康大數據提供的服務將被視為接受修改後的協議。
				<br /><br />

				<div class="list">二、帳號註冊</div>
				1、使用者在使用本服務前需要註冊一個“米因代理人”帳號。“米因代理人”帳號應當使用手機號碼綁定註冊，請用戶使用尚未與“米因代理人平台”帳號綁定，以及未被米因健康大數據根據本協議封禁的手機號碼註冊“米因代理人”帳號。米因健康大數據可以根據使用者需求或產品需要對帳號註冊和綁定的方式進行變更，而無須事先通知用戶。
				<br>
				2、如果註冊申請者有被米因健康大數據封禁的先例或涉嫌虛假註冊及濫用他人名義註冊，及其他不能得到許可的理由，米因健康大數據將拒絕其註冊申請。
				<br>
				3、鑒於“米因代理人”帳號的綁定註冊方式，您同意並允許米因健康大數據在註冊時將您的手機號碼及手機設備識別碼等資訊用於註冊。
				<br>
				4、在使用者註冊及使用本服務時，米因健康大數據需要搜集能識別使用者身份的個人資訊以便米因健康大數據可以在必要時聯繫用戶，以及為使用者提供代理平台相關服務。米因健康大數據搜集的資訊包括但不限於使用者的姓名、性別、居住地、身高、體重、出生日期、體型資訊、生化檢查指標等；米因健康大數據同意對這些資訊的使用將受限於本使用者協議第十條第3款使用者個人隱私資訊保護相關條款的約束。
				<br /><br />

				<div class="list">三、帳戶米因大數據科技有限公司		1、用戶一旦註冊成功，成為米因健康大數據“代理人平台”的用戶，將有權利使用自己的手機號及驗證碼隨時登陸米因健康大數據“代理人平台”獲取服務。
				<br>
				2、使用者對手機號及驗證碼的安全負全部責任，同時對以其帳戶進行的所有活動和事件負全責。
				<br>
				3、用戶不得以任何形式擅自轉讓或授權他人使用自己的米因健康大數據“代理人平台”帳戶。
				<br>
				4、如果使用者洩漏了手機號及驗證碼，有可能導致不利的法律後果，因此不管任何原因導致使用者的賬戶安全受到威脅，應該立即和米因健康大數據“代理人平台”客服人員取得聯繫，否則後果自負。
				<br /><br /></div>

				<div class="list">四、用戶聲明與保證</div>
				1、用戶承諾其年滿18歲，為具有完全民事行為能力的民事主體，且具有達成交易履行其義務的能力。
				<br>
				2、用戶有義務在註冊時提供自己的真實數據，並保證諸如手機號碼、姓名、所在地區等內容的有效性及安全性，保證米因健康大數據工作人員可以通過上述聯繫方式與使用者取得聯繫。同時，使用者也有義務在相關數據實際變更時及時更新有關註冊數據。
				<br>
				3、使用者通過使用米因健康大數據的過程中所製作、上載、複製、發佈、傳播的任何內容，包括但不限於帳號頭像、名稱、使用者說明等註冊資訊及認證數據，或文字、語音、圖片、視頻、圖文等發送、回復和相關連結頁面，以及其他使用帳號或本服務所產生的內容，不得違反中華人民共和國國家及香港特別行政區相關法律制度，包含但不限於如下原則：
				<br>
				（1）反對憲法所確定的基本原則的；
				<br>
				（2）危害國家安全，洩露國家秘密，顛覆國家政權，破壞國家統一的；
				<br>
				（3）損害國家榮譽和利益的；
				<br>
				（4）煽動民族仇恨、民族歧視，破壞民族團結的；
				<br>
				（5）破壞國家宗教政策，宣揚邪教和封建迷信的；
				<br>
				（6）散佈謠言，擾亂社會秩序，破壞社會穩定的；
				<br>
				（7）散佈淫穢、色情、賭博、暴力、兇殺、恐怖或者教唆犯罪的；
				<br>
				（8）侮辱或者誹謗他人，侵害他人合法權益的；
				<br>
				（9）含有法律、行政法規禁止的其他內容的。
				<br>
				
				4、用戶不得利用“米因代理人”帳號或本服務製作、上載、複製、發佈、傳播下干擾“米因健康大數據”正常運營，以及侵犯其他使用者或第三方合法權益的內容：
				<br>
				（1）含有任何性或性暗示的；
				<br>
				（2）含有辱駡、恐嚇、威脅內容的；
				<br>
				（3）含有騷擾、垃圾廣告、惡意資訊、誘騙資訊的；
				<br>
				（4）涉及他人隱私、個人資訊或數據的；
				<br>
				（5）侵害他人名譽權、肖像權、智慧財產權、商業秘密等合法權利的；
				<br>
				（6）含有其他干擾本服務正常運營和侵犯其他使用者或第三方合法權益內容的資訊。
				<br>

				5、使用者在使用米因健康大數據“代理人平台”服務的過程中，如涉及到其他個人的個人資訊的，需在使用前對其進行充分的解釋，使其完全瞭解米因健康大數據的服務內容、性質和相關協議條款，並獲取其授權認可。
				<br /><br />

				<div class="list">五、服務內容</div>
				1、米因健康大數據“代理人平台”具體服務內容由米因健康大數據根據實際情況提供，包括但不限於：
				<br>
				（1）線上註冊代理人；
				<br>
				（2）線上註冊並管理代理人下線；
				<br>
				（3）線上查詢代理人相關業績。
				<br>
				所有米因健康大數據“代理人平台”註冊用戶及代理人，均應與米因公司簽署代理人協議，該協議將由每位代理人上級負責跟進簽署，簽署後原件交回米因公司，米因公司收到無誤的簽署協議後，代理人資格方正式確立。負責管理的上級代理人將負責通知並協助管理代理人一切相關事宜。未能簽署代理人協議的代理人，米因公司有權暫不發放相關業績傭金，直到收到代理人簽署的、無誤的代理人協議。
				<br>
				2、米因健康大數據有權隨時審核或刪除用戶發佈/傳播的涉嫌違法或違反《港區國安法》，或者被米因健康大數據認為不妥當的內容（包括但不限於文字、語音、圖片、視頻、圖文等）。
				<br>
				3、所有發給使用者的通告及其他消息都可通過微信公眾號後台或者使用者所提供的聯繫方式發送。

				<br /><br />

				<div class="list">六、服務的終止</div>
				1、在下列情況下，米因健康大數據有權終止向使用者提供服務：
				<br>
				（1）在使用者違反本服務協議相關規定時，米因健康大數據有權終止向該使用者提供服務。如該用戶再一次直接或間接或以他人名義註冊為用戶的，一經發現，米因健康大數據有權直接單方面終止向該使用者提供服務；
				<br>
				（2）如米因健康大數據通過使用者提供的資訊與使用者聯繫時，發現用戶在註冊時填寫的聯繫方式已不存在或無法接通，米因健康大數據以其它聯繫方式通知使用者更改，而用戶在三個工作日內仍未能提供新的聯繫方式，米因健康大數據有權終止向該使用者提供服務；
				<br>
				（3）使用者不得通過程式或人工方式進行刷量或作弊，若發現用戶有作弊行為，米因健康大數據將立即終止服務，並有權扣留帳戶內金額和所有數據；
				<br>
				（4）一旦米因健康大數據發現使用者提供的數據或資訊中含有虛假內容，米因健康大數據有權隨時終止向該使用者提供服務；
				<br>
				（5）本服務條款終止或更新時，用戶明示不願接受新的服務條款；
				<br>
				（6）其它米因健康大數據認為需終止服務的情況。
				<br>

				2、服務終止後，米因健康大數據沒有義務為使用者保留原帳號中或與之相關的任何資訊，或轉發任何未曾閱讀或發送的資訊給使用者或第三方。
				<br /><br />

				<div class="list">七、服務的變更、中斷</div>
				1、鑒於網路服務的特殊性，使用者需同意米因健康大數據會變更、中斷部分或全部的網路服務，並刪除（不再保存）用戶在使用過程中提交的任何數據，而無需通知使用者，也無需對任何用戶或任何第三方承擔任何責任。
				<br>
				2、米因健康大數據需要定期或不定期地對提供網路服務的平台進行檢測或者更新，如因此類情況而造成網路服務在合理時間內的中斷，米因健康大數據無需為此承擔任何責任。
				<br /><br />

				<div class="list">八、服務條款修改</div>
				1、米因健康大數據有權隨時修改本服務條款的任何內容，一旦本服務條款的任何內容發生變動，米因健康大數據將會通過適當方式向使用者提示修改內容。
				<br>
				2、如果不同意米因健康大數據對本服務條款所做的修改，使用者有權停止使用服務。
				<br>
				3、如果使用者繼續使用服務，則視為使用者接受米因健康大數據對本服務條款所做的修改。
				<br /><br />

				<div class="list">九、免責與賠償聲明</div>
				1、若米因健康大數據已經明示其服務提供方式發生變更並提醒使用者應當注意事項，用戶未按要求操作所產生的一切後果由用戶自行承擔。
				<br>
				2、用戶明確同意其使用米因健康大數據所存在的風險將完全由其自己承擔，因其使用米因健康大數據而產生的一切後果也由其自己承擔。
				<br>
				3、用戶同意保障和維護米因健康大數據及其他使用者的利益，由於用戶在使用米因健康大數據有違法、不真實、不正當、侵犯第三方合法權益的行為，或使用者違反本協議項下的任何條款而給米因健康大數據及任何其他第三方造成損失，用戶同意承擔由此造成的損害賠償責任。
				<br /><br />

				<div class="list">十、隱私權聲明</div>
				1、適用範圍：
				<br>
				（1）在用戶註冊米因健康大數據帳戶時，根據要求提供的個人註冊資訊，其下線代理人註冊代理人服務所需的相關資訊；
				<br>
				（2）在用戶使用米因健康大數據，或訪問其相關網頁時，米因健康大數據自動接收並記錄的使用者流覽器上的伺服器數值，包括但不限於IP位址等數據及使用者要求取用的網頁記錄。
				<br>
				2、資訊使用：
				<br>
				（1）米因健康大數據不會向任何人出售或出借使用者的個人資訊，除非事先得到使用者的許可。
				<br>
				（2）米因健康大數據亦不允許任何第三方以任何手段收集、編輯、出售或者無償傳播使用者的個人資訊及涉及本“代理人平台”的一切資訊，包括但不限於客戶資訊、相關交易價格及數量等資訊、代理人利潤及業績數據等。任何用戶如從事上述活動，一經發現，米因健康大數據有權立即終止與該使用者的服務協議，查封其帳號。
				<br>
				（3）為達到服務使用者的目的，米因健康大數據可能通過使用使用者的個人資訊，向使用者提供服務，包括但不限於向使用者發出產品和服務資訊，或者與米因健康大數據合作夥伴共用資訊以便他們向使用者發送有關其產品和服務的資訊。
				<br>
				3、資訊披露：使用者的個人資訊將在下述情況下部分或全部被披露：
				<br>
				（1）經用戶同意，向第三方披露；
				<br>
				（2）根據法律的有關規定，或者行政或司法機構的要求，向第三方或者行政、司法機構披露；
				<br>
				（3）如果用戶出現違反中國大陸及香港特別行政區有關法律或者網站政策的情況，需要向第三方披露；
				<br>
				（4）為提供使用者所要求的產品和服務，而必須和第三方分享使用者的個人資訊；
				<br>
				（5）其它米因健康大數據根據法律或者網站政策認為合適的披露；
				<br>
				（6）使用者使用米因健康大數據時提供的銀行帳戶資訊，米因健康大數據將嚴格履行相關保密約定。
				<br /><br />

				<div class="list">十一、其他</div>
				1、米因健康大數據鄭重提醒使用者注意本協議中免除米因健康大數據責任和限制使用者權利的條款，請用戶仔細閱讀，自主考慮風險。未成年人應在法定監護人的陪同下閱讀本協議。
				<br>
				2、本協議的效力、解釋及糾紛的解決，適用於中華人民共和國法律。若使用者和米因健康大數據之間發生任何糾紛或爭議，首先應友好協商解決，協商不成的，用戶同意將糾紛或爭議提交米因健康大數據住所地有管轄權的人民法院管轄。
				<br>
				3、本協議的任何條款無論因何種原因無效或不具可執行性，其餘條款仍有效，對雙方具有約束力。
				<br>
				4、本協議最終解釋權歸上海米因大數據科技有限公司所有，並且保留一切解釋和修改的權力。
				<br>
				5、本協議從2020年12月21日起適用。
				<br /><br />

				因頒佈新廣告法規定，所有頁面不得出現絕對化用詞。本公司支持新廣告法，為不影響正常消費，明顯區域正在排查修改，並在此鄭重表態：本頁面所有絕對性用詞在此申明失效，不作為賠付理由。不明顯區域我們正在逐步排查和完善，感謝您的支持。
			</div>
		</van-popup>
		
	</div>
</template>

<script>
	import Vue from 'vue';
	import { Field, Button, Picker, Popup, CountDown, Toast } from 'vant';
	
	Vue.use(Field)
	   .use(Button)
	   .use(Picker)
	   .use(Popup)
	   .use(CountDown)
	   .use(Toast);
	   
	import { isHKPhone, isPhone } from '@/js/utils'
	import { getImageCode, getVerifyCode, agentLogin, agentGetInfo } from '@/js/axios'
	
	export default{
		name: 'agentLogin',
		data() {
			return {
				// 手机号登录
				mobile: '',
				imgCode: '',
				imgCodeUrl: '',
				code: '',
				invitation: '',
				
				imgCodeShow: false,
				
				loaIndex: 0,// 默认区号下标
				columns: [
					{ text: '852', value: '00852' },
					{ text: '86', value: '86' }
				],
				
				loaShow: false,
				
				onlyRead: false,// 邀请码是否只读
				
				sendCoding: false,// 正在发送验证码
				
				// 账号登录
				account: '',// 账号
				password: '',// 密码
				
				agrementShow: false,// 弹出协议
				
				type: 1,// 登录方式。1：手机号。2：账号。默认手机号登录
				canLogin: false// 需输入手机号验证码或账号密码后可点击登录
				
			}
		},
		watch: {
			// 切换登录方式时清空输入项
			type() {
				// 禁止登录
				this.canLogin = false;
				
				if(this.type == 1){// 手机号登录
					this.mobile = '';
					this.imgCode = '';
					this.imgCodeUrl = '';
					this.code = '';
					this.invitation = '';
					
				}else if(this.type == 2){// 账号登录
					var query = this.$route.query;
					if(query.account && query.password){// 扫码登录保存账号密码
						this.account = query.account;
						this.password = query.password;
						this.canLogin = true;
					}else{
						this.account = '';
						this.password = '';
					}
					
				}
				
			}
		},
		mounted() {
			if(this.$store.state.agentLogin){
				this.$router.replace('/agent');
			}else{
				// 完善信息返回上一步将信息填入
				var agentRegistInfo = sessionStorage.getItem('agentRegistInfo');
				if(agentRegistInfo){
					agentRegistInfo = JSON.parse(agentRegistInfo);
					console.log(agentRegistInfo)
					this.mobile = agentRegistInfo.mobile;
					this.code = agentRegistInfo.code;
					this.invitation = agentRegistInfo.invitation;
					
					this.canLogin = true;
				}
				
				var query = this.$route.query;
				if(query){
					// 邀请码扫码
					var queryInvit = query.invitCode;
					if(queryInvit){
						this.invitation = queryInvit;
						this.onlyRead = true;
					}
					
					// 后台账号开通扫码登录
					if(query.account && query.password){
						this.type = query.type;
						this.account = query.account;
						this.password = query.password;
					}
					
				}
				
			}
		},
		methods: {
			// 登录按钮是否可点击
			isCanLogin() {
				// 手机号登录                                              账号登录
				if((this.verifyMobile(true) && this.code.length >= 4) || (this.account && this.password)){
					this.canLogin = true;
				}else{
					this.canLogin = false;
				}
			},
			
			// 发送验证码
			sendCode() {
				if(this.verifyMobile()){
					this.getImgCode();
				}
			},
			// 获取图形验证码
			getImgCode(succallback) {
				getImageCode({
					phone: this.mobile
				}).then(res => {
					if(res.status == 1){
						this.imgCodeUrl = res.captcha;
						
						this.imgCodeShow = true;
					}else{
						Toast.fail(res.msg);
					}
				});
			},
			// 校验图形验证码
			verifyImgCode(val) {
				if(val.length >= 4){
					let data = {
						account: this.mobile,
						// type: (isPhone(account) || isHKPhone(account))?1:(isEmail(account)?90:''),//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码,
						type: 1,
						loa: isHKPhone(this.mobile)?'00852':'86',
						captcha: this.imgCode
					};
					getVerifyCode(data).then(res => {
						if(res.status == 1){
							this.sendCoding = true;
							this.$refs.codeCountDown.start();
							this.imgCodeShow = false;
							
							agentGetInfo({
								mobile: this.mobile
							}).then(res2 => {
								if(res2.code == 200){
									var inviteBy = res2.data.invite_by;
									if(inviteBy){
										this.invitation = inviteBy;
										this.onlyRead = true;
									}
								}
							});
							
						}else if(res.status == -1){
							Toast.fail(res.msg);
							this.imgCodeUrl = res.captcha;
						}else{
							Toast.fail(res.msg);
						}
					});
				}
			},
			
			// 登录
			login() {
				let data;
				
				if(this.type == 1){
					data = {
						mobile: this.mobile,
						code: this.code,
						invite_by: this.invitation
					}
				}else if(this.type == 2){
					data = {
						username: this.account,
						password: this.password,
						type: this.type
					}
				}
				
				agentLogin(data).then(res => {
					console.log(res)
					if(res.code == 200){// 已注册
						this.$store.dispatch('agentLogin',res.data.access_token).then(() => {
							Toast.success('登錄成功');
							
							let path = this.$route.query.toUrl?this.$route.query.toUrl:'/agent';
							
							if(res.data.userType == 10){// 后台管理账号
								path = '/agent/admin';
								// 存储用户类型，进入非后台页面跳回后台
								localStorage.setItem('agentType', res.data.userType);
							}
							this.$router.push(path);// userType为10进入后台
						})
						.catch(() => {
							
						});
					}else if(res.code == -1){// 未注册
						// 存储用户手机号等信息完善注册时提交
						sessionStorage.setItem('agentRegistInfo', JSON.stringify(data));
						
						this.$router.push({
							path: '/agent/perfectInfo',
							query: this.$route.query
						});
					}else{
						Toast.fail(res.msg);
					}
				});
					
			},
			
			// 检测手机号格式 参数:closeTip boolean,是否关闭错误提示.默认不关闭
			verifyMobile(closeTip) {
				if(isHKPhone(this.mobile) || isPhone(this.mobile)){
					return true;
				}else{
					closeTip?'':Toast.fail('手機號格式不正確');
					return false;
				}
			},
			
			// 倒计时结束
			countDownFinish() {
				this.sendCoding = false;
				this.$refs.codeCountDown.reset();
			},
			
			// 根据号码输入格式自动切换区号
			mobileChange(val) {
				if(isHKPhone(val)){
					this.loaIndex = 0;
				}else if(isPhone(val)){
					this.loaIndex = 1;
				}
				
				// 是否可登录
				this.isCanLogin();
				
			},
			
			// 切换电话号码区号
			checkLoa(item, index) {
				this.loaIndex = index;
				this.loaShow = false;
			}
		}
	}
</script>

<style scoped>
	.mobile >>> .van-field__label{
		width: 1.12rem;
		font-size: 0.3rem;
		text-align: left;
		padding-left: 0.12rem;
		border-right: 0.01rem solid #979797;
	}
	.mobile >>> input{
		padding-left: 0.31rem;
	}
	.account-login >>> .van-field__label{
		position: relative;
		width: 1.03rem;
		padding-left: 0.12rem;
		text-align: left;
	}
	.account-login >>> .van-field__label::after{
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 0.01rem;
		height: 0.3rem;
		background-color: #979797;
		transform: translateY(-50%);
	}
	.account-login >>> .van-field__control{
		padding-left: 0.32rem;
	}
</style>
<style lang="scss" scoped>
	.login{
		color: #3B4549;
		
		.nav{
			margin-bottom: 0.4rem;
			text-align: left;
			
			span{
				display: inline-block;
				position: relative;
				height: 0.6rem;
				line-height: 0.3rem;
				color: #8C9699;
				margin-right: 0.8rem;
				transition: all .3s;
				
				&::after{
					content: '';
					position: absolute;
					left: 50%;
					bottom: 0;
					width: 0;
					height: 0.04rem;
					border-radius: 0.04rem;
					background-color: #6883FB;
					transform: translateX(-50%);
					transition: all .3s;
				}
				
				&.active{
					color: #6883FB;
					font-weight: 500;
					
					&::after{
						width: 0.32rem;
					}
				}
			}
		}
		
		.form{
			padding-left: 0.72rem;
			
			.title{
				font-weight: 400;
				font-size: 0.56rem;
				line-height: 0.78rem;
				margin: 0.4rem 0 0.75rem;
				text-align: left;
				
				span,
				img{
					vertical-align: middle;
				}
				img{
					width: 1.9rem;
					height: 0.47rem;
					margin-left: 0.1rem;
				}
			}
			
			.van-field{
				margin-bottom: 0.44rem;
			}
			.send-code{
				border: none;
				font-size: 0.3rem;
				height: 0.3rem;
				line-height: 0.3rem;
				padding-right: 0.4rem;
			}
			.code-count-down{
				color: #ccc;
				padding-right: 0.4rem;
				
				.van-count-down{
					color: #ccc;
				}
			}
			
		}
		
		.login-btn{
			width: 3.24rem;
			height: 0.8rem;
			line-height: 1;
			border-radius: 0.08rem;
			margin: 0.68rem 0 0.18rem;
			padding: 0 0 0 0.2rem;
			
			img{
				width: 0.59rem;
				height: auto;
				margin: -0.1rem 0 0 0.11rem;
			}
			
			&.van-button--disabled{
				opacity: .8;
			}
		}
		
		.agrement{
			width: 80%;
			max-height: 85%;
			text-align: left;
			padding: 0.48rem;
			border-radius: 0.08rem;
			
			h2{
				font-size: 0.3rem;
				text-align: center;
				margin-bottom: 0.32rem;
			}
			.sub{
				line-height: 0.4rem;
				
				.list{
					font-weight: bold;
					margin-bottom: 0.12rem;
				}
			}
		}
		
		.img-code{
			width: 80%;
			padding: 0.56rem 0.4rem;
			border-radius: 0.08rem;
			
			.img-code-box{
				position: relative;
				
				.van-field{
					margin-top: 0.24rem;
				}
				
				img{
					position: absolute;
					right: 0;
					top: 0;
					width: auto;
					height: 100%;
					border-bottom: 0.01rem solid transparent;
				}
			}
		}
		
	}
</style>
